import { isAllItemsCancelled, isCancelledItem } from 'swag-common/utils/order';
import { DesignUploadMode } from 'swag-common/interfaces';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { findCheapestShipmentRate } from 'swag-common/business-logic/order/find-cheapest-shipment-rate.logic';
import { isItemSampleBox } from 'swag-common/utils/order/is-item-sample-box.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
export function getDataForGettingItemShippingCost(item, items) {
  const itemDataForShipping = {
    serviceCode: item.serviceCode,
    shipmentCharges: item.shipmentCharges
  };
  if (isItemBlankSampleOrPrintedSample(item)) {
    itemDataForShipping.isSample = true;
  }
  if (isBox(item) && !isItemSampleBox(item)) {
    itemDataForShipping.boxContent = (items || []).filter(i => {
      var _i$prod;
      return ((_i$prod = i.prod) === null || _i$prod === void 0 ? void 0 : _i$prod.designUploadMode) !== DesignUploadMode.box && i.boxItemsId === item.boxItemsId;
    });
    itemDataForShipping.fullBoxShipmentCharge = item.fullBoxShipmentCharge;
    itemDataForShipping.boxShipmentCharge = item.boxShipmentCharge || findCheapestShipmentRate(item.shipmentCharges || []);
  }
  return itemDataForShipping;
}
function getBoxContentSippingCost(boxContent) {
  let shipment = 0;
  boxContent.forEach(item => {
    if (isCancelledItem(item) && !isAllItemsCancelled(boxContent)) {
      return;
    }
    const selectedCharge = (item.shipmentCharges || []).find(charge => charge.serviceCode === item.serviceCode);
    shipment += selectedCharge && selectedCharge.totalCharges ? Math.ceil(selectedCharge.totalCharges) : 0;
  });
  return shipment;
}
function getBoxShippingCost(data, isBoxContentExcluded) {
  const {
    fullBoxShipmentCharge,
    boxShipmentCharge,
    boxContent
  } = data;
  let shipment = Math.ceil(Number(boxShipmentCharge === null || boxShipmentCharge === void 0 ? void 0 : boxShipmentCharge.totalCharges));
  if (fullBoxShipmentCharge) {
    shipment += Math.ceil(Number(fullBoxShipmentCharge.totalCharges));
  }
  if (!isBoxContentExcluded) {
    shipment += getBoxContentSippingCost(boxContent || []);
  }
  return shipment;
}
export function getItemShippingCost(data, isBoxContentExcluded) {
  const {
    shipmentCharges,
    serviceCode,
    boxShipmentCharge,
    isSample
  } = data;
  if (isSample) {
    return 0;
  }
  if (boxShipmentCharge) {
    return getBoxShippingCost(data, isBoxContentExcluded);
  } else {
    const shipment = (shipmentCharges || []).find(service => service.serviceCode === serviceCode);
    return shipment ? Math.ceil(Number(shipment.totalCharges)) : 0;
  }
}