import { DesignUploadMode } from 'swag-common/interfaces';
import { applyMultiplier } from 'swag-common/utils/tenant-price-settings';
import * as Colors from '../utils/colors';
const calculateSetupFeeValue = ({
  product,
  logos = {},
  texts,
  isSample = false,
  prodTime = 0,
  multiplier = 0
}) => {
  if (isSample || !product || product.designUploadMode === DesignUploadMode.fullDesign || product.designUploadMode === DesignUploadMode.box) {
    return 0;
  }
  const colors = Colors.getUniqueColorsNumberForAllSides(logos, texts);
  let colorsAmount = Object.values(colors).reduce((colorsSum, number) => {
    let amount = number;
    if (product.addOneColor) {
      amount += 1;
    }
    return amount + colorsSum;
  }, 0);
  if (product.colorIndependentPrice || product.colorIndependentScreenPrice) {
    colorsAmount = 1 * Object.keys(logos).length;
  }
  const timeCost = product.productionTimeList[prodTime] ? product.productionTimeList[prodTime].cost : 0;
  const setupFee = product.screenPrice * colorsAmount;
  const additionalProductionCost = setupFee * timeCost;
  const total = setupFee + additionalProductionCost;
  const result = applyMultiplier(total, multiplier);
  return Number(result);
};
export default calculateSetupFeeValue;