import _sum2 from "lodash/sum";
import _isEmpty2 from "lodash/isEmpty";
import _isFinite2 from "lodash/isFinite";
import _minBy3 from "lodash/minBy";
import { ADDITIONAL_PRODUCT_TIME_TEXT, CUSTOM_BOXES, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { urlModifier } from 'swag-client-common/libs/url-modifier';
import { getProductLink } from 'swag-client-common/utils/tricks';
import { isBundleAddon } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
export function getProductOutOfStockMinExpiredDate(supplierInventoryLevels) {
  var _minBy2;
  return (_minBy2 = _minBy3(supplierInventoryLevels, 'outOfStockExpireDate')) === null || _minBy2 === void 0 ? void 0 : _minBy2.outOfStockExpireDate;
}
export function getItemOutOfStockMinExpiredDate(supplierInventoryLevels, item) {
  let itemSkus = [];
  if (item.prod.hasSize) {
    var _item$variants$, _variant$sizeSkus;
    const variant = item.prod.variants[((_item$variants$ = item.variants[0]) === null || _item$variants$ === void 0 ? void 0 : _item$variants$.variantIndex) || 0];
    itemSkus = Object.values((_variant$sizeSkus = variant === null || variant === void 0 ? void 0 : variant.sizeSkus) !== null && _variant$sizeSkus !== void 0 ? _variant$sizeSkus : {});
  } else {
    if (item.itemSku) {
      itemSkus = [item.itemSku];
    } else if (item.productSku) {
      itemSkus = [item.productSku];
    }
  }
  const itemOutOfStockExpireDate = getProductOutOfStockMinExpiredDate(supplierInventoryLevels.filter(level => itemSkus.includes(level.variantSku)));
  return itemOutOfStockExpireDate || getProductOutOfStockMinExpiredDate(supplierInventoryLevels.filter(level => level.productId === item.prod._id));
}
export function getVisibleProductVariants(product) {
  return product.variants.filter(v => !v.isHidden);
}
export function getIsAlwaysInStock(product) {
  return Boolean(product.isAlwaysInStock);
}
export function checkIsApparel({
  designUploadMode,
  hasSize,
  sizeDependentPrice
} = {}) {
  return designUploadMode === DesignUploadMode.logo && !!hasSize && !sizeDependentPrice;
}
export function checkIsBundleAddon(product, productVariants = []) {
  return isBundleAddon(product.swagProductId, productVariants);
}
export function checkIsApparelInBundle(variant) {
  var _ref;
  if (variant.swagProductId && typeof variant.swagProductId === 'object') {
    return checkIsApparel(variant.swagProductId);
  }
  return (_ref = variant.sizeSettings && variant.sizeSettings.options.length > 1) !== null && _ref !== void 0 ? _ref : false;
}
export function checkIsSticker({
  designUploadMode,
  hasSize,
  sizeDependentPrice
} = {}) {
  return designUploadMode === DesignUploadMode.fullDesign && Boolean(hasSize) && Boolean(sizeDependentPrice);
}
export function checkIsValidForEditQty({
  designUploadMode,
  hidden
} = {}) {
  return designUploadMode === DesignUploadMode.logo && !hidden;
}
export function getVariantInStockLevelNumber(stockLevel) {
  if (_isFinite2(stockLevel)) {
    return stockLevel || 0;
  }
  return Infinity;
}
export function isAnySizeInStock(variant, productInStockLevelsByVariantSku, outOfStockThreshold) {
  if (Object.values(variant.sizeSkus).every(sku => sku === '')) {
    return true;
  }
  return Object.values(variant.sizeSkus).some(sku => sku !== '' && getVariantInStockLevelNumber(productInStockLevelsByVariantSku[sku]) > outOfStockThreshold);
}
export function getIsVariantInStock({
  variant,
  productInStockLevelsByVariantSku,
  outOfStockThreshold
}) {
  if (!variant || variant.isAlwaysInStockVariant) {
    return true;
  }
  if (!_isEmpty2(variant.sizeSkus)) {
    return isAnySizeInStock(variant, productInStockLevelsByVariantSku, outOfStockThreshold);
  }
  return getVariantInStockLevelNumber(productInStockLevelsByVariantSku[variant.productSku]) > outOfStockThreshold;
}
export function getInStockVariants({
  variants,
  isAlwaysInStock,
  productInStockLevelsByVariantSku,
  outOfStockThreshold,
  isLE
}) {
  if (isAlwaysInStock && !isLE) {
    return variants;
  }
  return variants.filter(variant => getIsVariantInStock({
    variant,
    productInStockLevelsByVariantSku,
    outOfStockThreshold
  }));
}
function productSupplierStockLevelsIterator(supplierInventoryLevels, getter) {
  return (supplierInventoryLevels || []).reduce((acc, item) => {
    if (!acc[item.productId]) {
      acc[item.productId] = {};
    }
    acc[item.productId][item.variantSku] = getter(item);
    return acc;
  }, {});
}
export function getProductSupplierStockLevelsByProductIdAndVariantSku(supplierInventoryLevels) {
  return productSupplierStockLevelsIterator(supplierInventoryLevels, item => item);
}
export function getProductInStockLevelsByProductIdAndVariantSku(product) {
  return productSupplierStockLevelsIterator(product.supplierInventoryLevels, item => item.inStock);
}
export function getSupplierOutOfStockThresholdsBySupplierId(suppliers) {
  return suppliers.reduce((acc, item) => {
    acc[item._id] = item.outOfStockThreshold;
    return acc;
  }, {});
}
export function getSupplierInStockQuantityBySize({
  variantSizeSkus,
  currentSizeName,
  stockLevelsByVariantSku,
  isAlwaysInStock
}) {
  if (isAlwaysInStock) {
    return Infinity;
  }
  const result = stockLevelsByVariantSku === null || stockLevelsByVariantSku === void 0 ? void 0 : stockLevelsByVariantSku[variantSizeSkus === null || variantSizeSkus === void 0 ? void 0 : variantSizeSkus[currentSizeName]];
  return _isFinite2(result) ? result : Infinity;
}
export const getIndexesOfOutOfStock = (inStockLevels, index, threshold) => {
  return inStockLevels[index] && inStockLevels[index].reduce((acc, item, i) => {
    if (item <= threshold) {
      acc.push(i);
    }
    return acc;
  }, []);
};
export const getIsDisabledBySizeForSampleProduct = (item, stockLevelsByProductIdAndVariantSku) => {
  const {
    variants,
    prod
  } = item;
  const stockLevelsByVariantSku = stockLevelsByProductIdAndVariantSku === null || stockLevelsByProductIdAndVariantSku === void 0 ? void 0 : stockLevelsByProductIdAndVariantSku[prod._id];
  const fullVariant = prod.variants[variants[0].variantIndex];
  const sizeSkus = (fullVariant === null || fullVariant === void 0 ? void 0 : fullVariant.sizeSkus) || {};
  return prod.size.options.map(size => {
    const inStock = getSupplierInStockQuantityBySize({
      variantSizeSkus: sizeSkus,
      currentSizeName: size,
      isAlwaysInStock: prod.isAlwaysInStock || (fullVariant === null || fullVariant === void 0 ? void 0 : fullVariant.isAlwaysInStockVariant),
      stockLevelsByVariantSku
    });
    return inStock === 0;
  });
};
export function getStockQuantityToShow({
  isOutOfStock,
  currentQuantity,
  availableQuantity,
  skipValidation = false
}) {
  if (skipValidation) {
    return null;
  }
  if (isOutOfStock) {
    return 0;
  }
  return currentQuantity > availableQuantity ? availableQuantity : null;
}
export function getBoxProductDataForBoxBuilderPopup(product) {
  if (!product) {
    return null;
  }
  const additionalProductionTimeText = product !== null && product !== void 0 && product.specialProductType && CUSTOM_BOXES.includes(product.specialProductType) ? ADDITIONAL_PRODUCT_TIME_TEXT : null;
  return {
    img: product.logo,
    name: product.name,
    type: product.specialProductType,
    description: product.descr,
    pricePerBox: product.price[0][0][0],
    assemblyFee: product.assemblyFee || 0,
    productionTimeDays: product.productionTimeList[0].time,
    additionalProductionTimeText,
    requireTemplateDesign: product.specialProductType === SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX,
    isBoxForSuperSpeed: Boolean(product.isBoxForSuperSpeed)
  };
}
export const getTotalCount = (quantities = []) => _sum2(quantities);
export const getSupplierStockProduct = ({
  product,
  variant,
  size,
  quantities,
  stockLevelsByVariantSku
}) => {
  const {
    hasSize,
    isAlwaysInStock,
    sizeDependentPrice
  } = product;
  if (hasSize && size && !sizeDependentPrice && variant) {
    return quantities.map((q, index) => {
      var _size$options;
      return getSupplierInStockQuantityBySize({
        variantSizeSkus: variant.sizeSkus,
        currentSizeName: (size === null || size === void 0 ? void 0 : (_size$options = size.options) === null || _size$options === void 0 ? void 0 : _size$options[index]) || null,
        stockLevelsByVariantSku,
        isAlwaysInStock: isAlwaysInStock || variant.isAlwaysInStockVariant
      });
    });
  }
  if (variant !== null && variant !== void 0 && variant.isAlwaysInStockVariant) {
    return [Infinity];
  }
  if (variant !== null && variant !== void 0 && variant.productSku) {
    return [stockLevelsByVariantSku[variant.productSku] || Infinity];
  } else {
    return [Infinity];
  }
};
export const getDifferenceBetweenOrderedQuantitiesAndStock = ({
  variantStock,
  quantities,
  size
}) => {
  return variantStock.reduce((difference, stock, position) => {
    if (stock === Infinity) {
      return difference;
    }
    const delta = stock - quantities[position];
    if (delta < 0) {
      var _size$options2;
      const label = (size === null || size === void 0 ? void 0 : (_size$options2 = size.options) === null || _size$options2 === void 0 ? void 0 : _size$options2[position]) || '';
      difference[position] = `${quantities[position]} but only ${Math.abs(stock)}${label ? ' of ' + label + ' size' : ''}`;
    }
    return difference;
  }, []);
};
export function getLink({
  seoSlug,
  title = '',
  childProductIds = []
}) {
  const [linkForPatagonia] = title.split('__');
  const link = getProductLink(seoSlug);
  return childProductIds.length > 0 ? urlModifier(`/${linkForPatagonia}`) : link;
}
export const getProductsFromItem = item => {
  const {
    prod,
    variants
  } = item;
  return variants.map(variant => {
    const {
      _id = '',
      name = '',
      variants: prodVariants = []
    } = prod || {};
    const {
      variantIndex,
      quantities
    } = variant;
    const {
      color: colorValue = '',
      colorFamily: colorName = ''
    } = prodVariants[variantIndex] || {};
    const quantity = variant.quantities.reduce((acc, curr) => acc + curr, 0);
    const size = checkIsApparel(prod) && prod !== null && prod !== void 0 && prod.size.options ? prod === null || prod === void 0 ? void 0 : prod.size.options.reduce((acc, curr, index) => {
      if (quantities[index] > 0) {
        return `${acc}${acc ? ', ' : ''}${curr}-${quantities[index]}`;
      }
      return acc;
    }, '') : '';
    return {
      _id,
      name,
      colorValue,
      colorName,
      quantity,
      size
    };
  });
};
export const getProductsFromItems = items => items.reduce((products, item) => [...products, ...getProductsFromItem(item)], []);