import _debounce from "lodash/debounce";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { ERROR_CODE_TO_EXPLANATION } from 'swag-client-common/constants/error-code-explanations';
import { PERMISSION_ERROR_POPUP } from 'swag-client-common/constants';
import { FORBIDDEN_ERROR_CODE } from 'swag-common/constants/error-codes';
export function createAsyncAction(opts) {
  const {
    preFn,
    success,
    error,
    transformData,
    debounceTime,
    debounceTimeGlobal,
    takeLatest
  } = opts;
  let {
    fn
  } = opts;
  let aborter;
  const createSuccess = typeof success === 'function' ? success : body => ({
    type: success,
    data: body
  });
  let createError = null;
  if (typeof error === 'function') {
    createError = error;
  } else if (typeof error !== 'undefined') {
    createError = err => errorAction(error)(err);
  }
  if (debounceTime) {
    fn = _debounce(fn, debounceTime);
  }
  let runningFunction = (data, dispatch, getState) => {
    let transformedData = data;
    if (takeLatest && aborter) {
      aborter();
    }
    if (preFn && preFn(dispatch, getState, data)) {
      return;
    }
    if (transformData) {
      transformedData = transformData(data, getState, dispatch);
      if (typeof transformedData === 'boolean' && transformedData) {
        return;
      }
    }
    return new Promise(resolve => {
      let isAborted = false;
      aborter = () => {
        isAborted = true;
      };
      fn(transformedData, (err, res) => {
        if (isAborted) {
          return;
        }
        const error = transformError(err);
        if (error && error.status === FORBIDDEN_ERROR_CODE) {
          dispatch(showPopup({
            popup: PERMISSION_ERROR_POPUP
          }));
        }
        let event = null;
        if (error) {
          event = createError ? createError(error, transformedData, dispatch, data) : null;
        } else {
          event = createSuccess(res, transformedData, dispatch, data, getState);
        }
        resolve(event);
        if (event) {
          dispatch(event);
        }
        if (!error && opts.postSuccess) {
          opts.postSuccess(dispatch, getState, transformedData, res, data);
        }
        if (error && opts.postError) {
          opts.postError(dispatch, getState, error);
        }
        return null;
      });
    });
  };
  if (debounceTimeGlobal) {
    runningFunction = _debounce(runningFunction, debounceTimeGlobal);
  }
  return data => (dispatch, getState) => {
    return runningFunction(data, dispatch, getState);
  };
}

/**
 * Create request error action of `type`
 *
 * @param type Action type
 * @return {Function}
 */
export const errorAction = type => err => ({
  type,
  err,
  error: err
});

/**
 * Create request success action of `type`
 *
 * @param type Action type
 * @return {Function}
 */
export const successAction = type => body => ({
  type,
  body
});

/**
 * Create empty action of `type`
 *
 * @param type Action type
 * @return {Object}
 */
export const emptyAction = type => () => ({
  type
});
export const createRequest = ({
  fn,
  data
}) => {
  return new Promise((resolve, reject) => {
    fn(data, (err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(res);
    });
  });
};
function transformError(error) {
  var _ERROR_CODE_TO_EXPLAN;
  if (!error) {
    return error;
  }
  const modifiedErrorObject = _objectSpread({}, error, {
    code: error.code || error.message,
    message: (_ERROR_CODE_TO_EXPLAN = ERROR_CODE_TO_EXPLANATION[error.message]) !== null && _ERROR_CODE_TO_EXPLAN !== void 0 ? _ERROR_CODE_TO_EXPLAN : error.message
  });
  if (modifiedErrorObject !== null && modifiedErrorObject !== void 0 && modifiedErrorObject.data) {
    try {
      const data = JSON.parse(modifiedErrorObject.data);
      _extends(modifiedErrorObject, {
        data
      });
      return modifiedErrorObject;
    } catch (e) {
      return modifiedErrorObject;
    }
  }
  return modifiedErrorObject;
}