import _isFinite from "lodash/isFinite";
import _isEqual from "lodash/isEqual";
import { isItemBoxSpecialProduct } from 'swag-common/utils/order/item-is-box.util';
import { DesignUploadMode } from 'swag-common/interfaces';
import { FRONT, SPECIAL_PRODUCT_TYPES, SUPER_SPEED_PRODUCTION_OPTION } from '../../constants/product.constants';
import { getLevelBySku } from './is-non-ps-out-of-stock.util';
export { spreadQuantities } from './spread-quantities.util';
const toComparableObject = variant => {
  return Object.keys(variant.images).reduce((acc, key) => {
    var _variant$images$key$;
    if (variant.images[key] && (_variant$images$key$ = variant.images[key][0]) !== null && _variant$images$key$ !== void 0 && _variant$images$key$.name) {
      acc[variant.images[key][0].name] = variant.images[key][0].name;
    }
    return acc;
  }, {});
};
export const areProductVariantsValid = product => {
  if (!product.variants || !product.variants.length) {
    return false;
  }
  return !!product.variants.reduce((acc, variant) => {
    return _isEqual(toComparableObject(variant), acc) ? acc : null;
  }, toComparableObject(product.variants[0]));
};

/**
 *  Images in variants for all colors should exists.
 *  If variant marked as `isHidden` it is skipped.
 */
export const areProductVariantsImagesExists = product => {
  if (!product.variants || !product.variants.length) {
    return false;
  }
  const imagesKeys = Object.keys(product.variants[0].images);
  return product.variants.every(variant => {
    if (variant.isHidden) {
      return true;
    }
    const {
      images
    } = variant;
    return imagesKeys.every(imagesKey => {
      if (images[imagesKey] === null) {
        return true;
      }
      return Array.isArray(images[imagesKey]) && !!images[imagesKey][0].url;
    });
  });
};
export const areVariantSKUsValid = variants => variants.every(variant => {
  const productSKUEntries = variants.filter(v => v.productSku === variant.productSku);
  if (!variant.productSku || productSKUEntries.length > 1) {
    return false;
  }
  return true;
});
export const areProductVariantsMatch = (firstProductVariants, secondProductVariants) => {
  if (!firstProductVariants[0] || !secondProductVariants[0]) {
    return false;
  }
  return _isEqual(toComparableObject(firstProductVariants[0]), toComparableObject(secondProductVariants[0]));
};
export const getItemQuantityPerBox = (currentItem, newQuantity, box) => {
  if (isItemBoxSpecialProduct(currentItem)) {
    return 1;
  }
  if (currentItem.quantityPerBox && box.quantity * currentItem.quantityPerBox <= currentItem.quantity) {
    return currentItem.quantityPerBox;
  }
  return Math.floor(newQuantity / box.quantity);
};
export const accumulatorCheck = product => {
  return Boolean(product && product.childProductIds);
};
export const getProductImage = ({
  product,
  variantInd = 0,
  view = FRONT,
  sizeInd = 0
}) => {
  var _product$variants, _product$variants$var, _product$variants$var2, _product$variants$var3;
  if (!product) {
    return null;
  }
  const imgIndex = product.sizeDependentImages ? sizeInd : 0;
  const image = (_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : (_product$variants$var = _product$variants[variantInd]) === null || _product$variants$var === void 0 ? void 0 : (_product$variants$var2 = _product$variants$var.images) === null || _product$variants$var2 === void 0 ? void 0 : (_product$variants$var3 = _product$variants$var2[view]) === null || _product$variants$var3 === void 0 ? void 0 : _product$variants$var3[imgIndex];
  return image || {
    url: product.logo,
    size: {},
    rectangle: {}
  };
};
export function isProductApparel(product) {
  return product.designUploadMode === DesignUploadMode.logo && product.hasSize && !product.sizeDependentPrice;
}
export function getProductInStockLevelsByVariantSku({
  supplierInventoryLevels
}) {
  const levels = {};
  (supplierInventoryLevels || []).forEach(item => {
    if (item.variantSku) {
      levels[item.variantSku] = item.inStock;
    }
  });
  return levels;
}
export const getTotalInStock = levels => {
  let res = 0;
  for (const variantSku in levels) {
    res += levels[variantSku];
  }
  return res;
};
export function checkIsProductSampleBox(product) {
  return product.specialProductType === SPECIAL_PRODUCT_TYPES.SAMPLE_BOX;
}
export function checkIsProductColoredeBox(product) {
  return product.specialProductType === SPECIAL_PRODUCT_TYPES.COLORED_BOX;
}
export function checkIsProductCustomBox(product) {
  return product.specialProductType === SPECIAL_PRODUCT_TYPES.CUSTOM_BOX;
}
export function checkIsProductBrownBox(product) {
  return product.specialProductType === SPECIAL_PRODUCT_TYPES.BROWN_BOX;
}
export function checkIsProductFullyCustomBox(product) {
  return product.specialProductType === SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX;
}
export function getProductName(product) {
  return product === null || product === void 0 ? void 0 : product.name;
}
export function checkIsProductBox(product) {
  return product.designUploadMode === DesignUploadMode.box;
}
const getInStockSupplierInvLvlBySku = (supplierInvLvls, sku) => {
  var _getLevelBySku$inStoc, _getLevelBySku;
  return (_getLevelBySku$inStoc = (_getLevelBySku = getLevelBySku(supplierInvLvls, sku)) === null || _getLevelBySku === void 0 ? void 0 : _getLevelBySku.inStock) !== null && _getLevelBySku$inStoc !== void 0 ? _getLevelBySku$inStoc : 0;
};
export const getTotalStockLvls = lvls => lvls.reduce((acc, lvl) => {
  var _lvl$inStock;
  return acc + ((_lvl$inStock = lvl.inStock) !== null && _lvl$inStock !== void 0 ? _lvl$inStock : 0);
}, 0);
export const getQuantityBySupplierInventoryLevels = (variants, supplierInventoryLevels) => {
  if (!variants.length) {
    return getTotalStockLvls(supplierInventoryLevels);
  }
  return variants.reduce((acc, {
    sizeSkus,
    productSku,
    isHidden
  }) => {
    if (isHidden) {
      return acc;
    }
    let inStock;
    if (sizeSkus) {
      inStock = Object.values(sizeSkus).reduce((acc, sizeSku) => {
        const inStockQty = getInStockSupplierInvLvlBySku(supplierInventoryLevels, sizeSku);
        return acc + inStockQty;
      }, 0);
    } else {
      inStock = getInStockSupplierInvLvlBySku(supplierInventoryLevels, productSku);
    }
    return acc + inStock;
  }, 0);
};
export const isSuperSpeedProductionOption = option => {
  if (!option || !option.name) {
    return false;
  }
  return option.name.toLowerCase() === SUPER_SPEED_PRODUCTION_OPTION.toLowerCase();
};
export const filterSuperSpeedFromProductionTimeList = list => {
  return (list === null || list === void 0 ? void 0 : list.filter(({
    name
  }) => name !== SUPER_SPEED_PRODUCTION_OPTION)) || [];
};
export const hsCodeRegEx = /^\d+(\.\d+)*$/i;
export function getProductOutOfStockThreshold(product) {
  var _product$limitedEditi;
  if ((_product$limitedEditi = product.limitedEdition) !== null && _product$limitedEditi !== void 0 && _product$limitedEditi.isLimitedEdition) {
    return 0;
  }
  if (_isFinite(product.outOfStockThreshold)) {
    return product.outOfStockThreshold || 0;
  }
  if (_isFinite(product.supplierOutOfStockThreshold)) {
    return product.supplierOutOfStockThreshold || 0;
  }
  return 0;
}
export const getPrintLocationNameFromVariants = (prod, locationKey) => {
  var _prod$variants$, _printLocation$;
  const printLocation = prod === null || prod === void 0 ? void 0 : (_prod$variants$ = prod.variants[0]) === null || _prod$variants$ === void 0 ? void 0 : _prod$variants$.images[locationKey];
  if (!printLocation) {
    return locationKey;
  }
  return ((_printLocation$ = printLocation[0]) === null || _printLocation$ === void 0 ? void 0 : _printLocation$.name) || locationKey;
};
export function isSimpleNonApparelProduct(product) {
  return product.designUploadMode === DesignUploadMode.logo && !product.sizeDependentPrice && !product.hasSize;
}