import { DesignUploadMode } from 'swag-common/interfaces';
import { Price } from './price';
export const getBiggestScreenPriceByColorAmount = (items, products) => {
  if (products.length === 1) {
    return 0;
  }
  const screenPrices = products.map(product => {
    if (!product || product.designUploadMode === DesignUploadMode.box) {
      return 0;
    }
    const foundItem = items.find(item => item.prodId === String(product === null || product === void 0 ? void 0 : product._id));
    if (!foundItem) {
      throw new Error('Item product was not found');
    }
    const amountOfSides = Object.keys(foundItem.logos || {}).length;
    const colors = Price.getScreensAmountForImage(product, foundItem.colors, amountOfSides);
    return colors * product.screenPrice / amountOfSides;
  });
  return Math.max.apply(null, screenPrices);
};