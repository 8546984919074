function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { getItemPricing } from './order/group-items-as-related-and-default.logic';
import getSetupFeeFromRelatedItems from './get-setup-fee-for-related-items.logic';
function getRelatedItems(items) {
  const relatedItemIds = {};
  const groupedRelatedItems = [];
  items.forEach(item => {
    if (relatedItemIds[String(item._id)] || !item.asRelatedItemId) {
      return null;
    }
    const relatedToThisItems = items.filter(innerItem => {
      return innerItem.asRelatedItemId === item.asRelatedItemId;
    });
    if (relatedToThisItems.length > 1) {
      // @todo. auto generated. fix this error
      // @ts-ignore
      groupedRelatedItems.push(relatedToThisItems.map(getItemPricing));
      const ids = relatedToThisItems.reduce((acc, item) => {
        acc[String(item._id)] = item._id;
        return acc;
      }, {});
      _extends(relatedItemIds, ids);
    }
  });
  return {
    relatedItemIds,
    groupedRelatedItems
  };
}
export default function getTotalSetupFeeForAllItems(items) {
  const {
    relatedItemIds,
    groupedRelatedItems
  } = getRelatedItems(items);
  const setupFeeFromRelatedItems = groupedRelatedItems.reduce((sum, array) => {
    const maxSetupFee = getSetupFeeFromRelatedItems(array);
    return maxSetupFee + sum;
  }, 0);
  return setupFeeFromRelatedItems + items.reduce((setupFeeSum, incomingItem) => {
    if (relatedItemIds[String(incomingItem._id)]) {
      return setupFeeSum;
    }
    const item = getItemPricing(incomingItem);
    return setupFeeSum + item.setupFee;
  }, 0);
}