function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import getUniqueSizeSet from 'swag-common/business-logic/products/get-unique-size-set.logic';
import { SPECIAL_PRODUCT_BUNDLE_ADDON_TYPES, SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { isBoxType } from './product-is-box-type.util';
export function isBundle(product) {
  var _product$bundleConten;
  return Boolean((product === null || product === void 0 ? void 0 : (_product$bundleConten = product.bundleContent) === null || _product$bundleConten === void 0 ? void 0 : _product$bundleConten.length) > 0);
}
export function isBundleItem(item) {
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId);
}
export function isInBundleItem(item) {
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId) && !isBundleBox(item);
}
export function isBundleBox(item) {
  var _item$prod;
  return Boolean(item === null || item === void 0 ? void 0 : item.bundleCustomProductId) && (item === null || item === void 0 ? void 0 : (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : _item$prod.designUploadMode) === DesignUploadMode.box;
}
export function isBundleAddon(swagProduct, variants = []) {
  return !!(swagProduct !== null && swagProduct !== void 0 && swagProduct.specialProductType && SPECIAL_PRODUCT_BUNDLE_ADDON_TYPES.includes(swagProduct.specialProductType) && variants.every(v => {
    var _v$boxContent;
    return !(v !== null && v !== void 0 && (_v$boxContent = v.boxContent) !== null && _v$boxContent !== void 0 && _v$boxContent.length);
  }));
}
export function isBundleNotecard(swagProductId, variants = []) {
  return isBundleAddon(swagProductId, variants) && (swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
}
export function findNotecardInBundleContent(bundleContent) {
  return bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.find(item => {
    var _item$swagProductId;
    return (item === null || item === void 0 ? void 0 : (_item$swagProductId = item.swagProductId) === null || _item$swagProductId === void 0 ? void 0 : _item$swagProductId.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
  });
}
export function hasNotecardInBundle(bundleContent) {
  return Boolean(findNotecardInBundleContent(bundleContent));
}
export function findPackagingInBundleContent(bundleContent) {
  return bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.find(item => isBoxType(item === null || item === void 0 ? void 0 : item.swagProductId));
}
export function hasPackagingInBundle(bundleContent) {
  return Boolean(findPackagingInBundleContent(bundleContent));
}
export function isBundleWithSizes(product) {
  var _product$bundleConten2;
  return Boolean(product === null || product === void 0 ? void 0 : (_product$bundleConten2 = product.bundleContent) === null || _product$bundleConten2 === void 0 ? void 0 : _product$bundleConten2.some(item => (item === null || item === void 0 ? void 0 : item.sizeSettings) && (item === null || item === void 0 ? void 0 : item.sizeSettings.options.length) > 1));
}
export function getBundleSizeSet(bundle) {
  var _bundle$variants$, _bundle$variants$$bun;
  if (!bundle) {
    return {};
  }
  const bundleProducts = (_bundle$variants$ = bundle.variants[0]) === null || _bundle$variants$ === void 0 ? void 0 : (_bundle$variants$$bun = _bundle$variants$.bundleContent) === null || _bundle$variants$$bun === void 0 ? void 0 : _bundle$variants$$bun.map(({
    product: {
      swagProductId,
      sizeSettings
    }
  }) => {
    var _sizeSettings$options;
    return _objectSpread({}, swagProductId, {
      hasSize: (swagProductId === null || swagProductId === void 0 ? void 0 : swagProductId.hasSize) || Boolean((sizeSettings === null || sizeSettings === void 0 ? void 0 : (_sizeSettings$options = sizeSettings.options) === null || _sizeSettings$options === void 0 ? void 0 : _sizeSettings$options.length) > 1),
      sizeId: sizeSettings && sizeSettings._id,
      productSize: sizeSettings
    });
  });
  return getUniqueSizeSet(bundleProducts || []);
}